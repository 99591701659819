/* COMMON CLASSES */

:root {
  --cg-orange: #dd4536;
  --cg-green: #179e5a;
  --cg-yellow: #f5b417;
  --cg-text: #434343;
  --bg-gray: #fbfbfb;
}

/* ABOUT US - BANNER */

.banner {
  background-color: #fbfbfb;
  padding-right: 20px;
  padding-left: 20px;
}

.banner .page-title-wrapper {
  display: table;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner .page-title-wrapper .page-title-captions {
  display: table-cell;
  vertical-align: middle;
  width: 60%;
}

.banner .page-title-wrapper .page-title-captions h3 {
  font-size: 90px;
  line-height: 80px;
  font-weight: 800;
  letter-spacing: -6.5px;
}

.banner .page-title-wrapper .page-title-captions h3 span {
  color: var(--cg-green);
}

.banner .page-title-wrapper ul {
  display: table-cell;
  vertical-align: middle;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 40%;
  text-align: right;
}

.banner .page-title-wrapper ul li {
  margin: 0;
  padding: 0;
  display: inline;
  color: #9d9d9d;
  font-size: 12px;
  margin-left: 2px;
  font-weight: 700;
  padding-left: 30px;
}

.banner .page-title-wrapper ul li a {
  color: #9d9d9d;
  margin: 0 30px;
}

.banner .page-title-wrapper ul li:first-child {
  margin-left: 0;
}

.banner .page-title-wrapper ul li:last-child {
  color: #434343;
}

.banner .page-title-wrapper ul li:after {
  content: "/";
}

.banner .page-title-wrapper ul li:last-child::after {
  content: "";
}

/* ABOUT US -  PROJECT SHOWCASE */

.project-showcase .phaseDots {
  display: flex;
  justify-content: space-between;
  width: 55vw;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 0px;
  margin-left: 25px;
}

.project-showcase .phaseDots p.yellow-bg {
  color: white;
  position: relative;
  background: var(--cg-yellow);
  padding: 12px 20px;
  padding-right: 38px;
}

.project-showcase .phaseDots p.yellow-bg::before {
  content: "";
  width: 28px;
  height: 40px;
  background: var(--cg-green);
  position: absolute;
  top: 0px;
  right: -11px;
  transform: skew(-30deg);
}

.project-showcase .phaseDots button::before {
  content: "0";
}
.project-showcase .phaseDots button::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: -1;
    padding: 0px;
    top: 0px;
    left: 0px;
    transform: skewX(-30deg);
}
.project-showcase .phaseDots p:last-child button:last-child::before {
  content: none;
}

/* .project-showcase .phaseDots p:before {
  content: "-";
  color: rgba(255, 255, 255, 0.5);
} */
.project-showcase .phaseDots button {
  color: white;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  z-index: 2;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
}

.project-showcase .column-6:focus {
  outline: none;
}

.project-showcase {
  background-color: var(--cg-green);
  padding-bottom: 0;
  padding-top: 80px;
}

.project-showcase .single-pro-showcase .flex-row {
  padding-bottom: 0;
}
.section-5 .brains-list .tab-list-item.tab-list-active::before {
  background: var(--cg-yellow);
}
.section-5 .brains-list .tab-list-item.tab-list-active {
  opacity: 1;
}

.single-pro-showcase {
  outline: none;
}

.single-pro-showcase h2 {
  color: white;
  padding-top: 0;
  margin-top: 15px;
  max-width: 95%;
  word-break: break-word;
}

.single-pro-showcase p {
  font-size: 16px;
  line-height: 28px;
  color: white;
  max-width: 80%;
}

.single-pro-showcase .btn {
  color: white;
  border-color: white;
}

.single-pro-showcase .btn:hover {
  background-color: transparent;
  color: white;
}

.showcase-img {
  padding-top: 70px;
}

.single-pro-showcase .btn {
  background-color: transparent;
}
.single-pro-showcase .btn:hover {
  background-color: var(--cg-orange) !important;
  border: 1px solid var(--cg-orange);
}

/* ABOUT US - TESTIMONIALS */

.phase-tesimonials .container {
  position: relative;
}

.phase-tesimonials .container:before {
  position: absolute;
  content: "";
  /*background-image: url("/images/yellowdots_03.png");*/
  background-repeat: no-repeat;
  width: 140px;
  top: 2%;
  height: 90px;
  z-index: -1;
  left: -100px;
}

.phase-tesimonials .single-test-phase h2 {
  padding-top: 0px;
}

.phase-tesimonials .single-test-phase .column-8 {
  margin-top: 95px;
}

.phase-tesimonials .single-test-phase .btn {
  margin-top: 95px;
}

.phase-tesimonials .single-test-phase p,
.phase-tesimonials .single-test-phase p a,
.phase-tesimonials .short-text {
  font-size: 25px;
  line-height: 45px;
  letter-spacing: -0.4px;
  font-weight: 700;
  color: var(--cg-text);
}

.phase-tesimonials .readMoreText {
  color: var(--cg-orange) !important;
  padding-left: 20px;
}

.phase-tesimonials .single-test-phase p a {
  color: var(--cg-orange);
}

.phase-tesimonials .single-test-phase img {
  margin-bottom: 40px;
}

.phase-tesimonials .single-test-phase .para-gra {
  font-size: 16px;
}

.phase-tesimonials .single-test-phase:focus {
  outline: none;
}

/* ABOUT US - BRAINS OF CG */

.section-5.aboutBrains {
  background-color: #547dbf;
}

.section-5.aboutBrains .flex-row .flex-row {
  padding-right: 0;
  padding-left: 0;
}

.section-5.aboutBrains .tab-list {
  width: 750px;
}

.section-5.aboutBrains .yellow-bg::before {
  background: #547dbf;
}

.section-5.aboutBrains .yellow-bg {
  width: 135px;
}

/* .brains-behind-section .flex-row {
padding-bottom: 0;
}
.brains-behind-section .explore-list p.explore-fir-list::before {
background-color: #547dbf;
} 
/* .brains-behind-section .explore-list {
width: 50%;
}
.single-brain .flex-row {
padding: 0;
}
.single-brain {
color: #fff;
}
.single-brain h2 {
width: 95%;
} */

/* .single-brain h4 {
font-size: 25px;
line-height: 45px;
font-weight: 700;
}
.single-brain p {
color: white;
line-height: 20px;
}
.single-brain .single-brain-img {
position: relative;
}
.single-brain .single-brain-img:before {
content: "";
position: absolute;
} */
.brain-image-container {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
}
.brain-image-container img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.single-brain-img.diamond {
  width: 420px;
  height: 420px;
  border-radius: 20px;
  box-shadow: -69px 53px 46px 0px rgba(0, 0, 0, 0.4);
  position: relative;
}

.brain-img-column {
  padding: 140px 0px;
}

.single-brain-img.diamond::before {
  /* content: url(/images/aboutWave.png); */
  width: 100px;
  height: auto;
  position: absolute;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 179px;
  right: -41px;
}

.single-brain-img.diamond::after {
  /* content: url(/images/aboutDots.png); */
  width: 100px;
  height: auto;
  position: absolute;
  left: -121px;
  bottom: 150px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

h3.brains-position {
  color: white;
  line-height: 1.7em;
  font-size: 22px;
  font-weight: 700;
}

p.brains-position {
  font-weight: 600 !important;
}

/* ABOUT US - TRAINERS */

/* .trainers-testimonials .explore-list {
width: 80%;
}
.trainers-testimonials .explore-list p.explore-fir-list::before {
background-color: white;
}
.trainers-testimonials .explore-list a,
.trainers-testimonials .explore-list p {
color: black;
}
.trainers-testimonials .single-trainer-phase h2 {
line-height: 80px;
}
.trainers-testimonials .single-trainer-phase p {
font-size: 16px;
line-height: 28px;
max-width: 85%;
} */

.section-3.trainers-section {
  background: white;
}

.section-3.trainers-section .flex-row .flex-row {
  display: block;
  padding-top: 0px;
}

.section-3.trainers-section .trainer-content h2,
.section-3.trainers-section .trainer-content p,
.section-3.trainers-section .trainer-content a {
  color: var(--cg-text);
}

.section-3.trainers-section .trainer-content a {
  border-color: var(--cg-text);
}

.section-3.trainers-section .trainer-content a:hover {
  border-color: var(--cg-green);
  color: white;
}

.trainers-list {
  /* margin-top: 70px; */
  position: relative;
  /* width: 50%; */
}

.trainers-list .tab-list {
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: absolute;
  top: 43px;
  left: 210px;
  /* width: 294px; */
}

.trainers-list.overview-list .tab-list {
  width: inherit;
  left: 176px;
}

.trainers-list .yellow-bg {
  display: inline-block;
  line-height: inherit;
  color: white;
  position: relative;
  background: var(--cg-yellow);
  padding: 12px 20px;
  font-size: 12px;
  padding-right: 40px;
}

.trainers-list .yellow-bg::before {
  content: "";
  width: 25px;
  height: 37px;
  background: white;
  position: absolute;
  top: 0px;
  right: -11px;
  transform: skew(-30deg);
}

.trainers-list .tab-list-item {
  color: var(--cg-text);
  font-size: 12px;
  font-weight: 700;
  border: none;
  padding: 0px;
  /* margin: 0px; */
    margin: 0px 17px;
  display: block;
  opacity: 0.6;
}
.trainers-list .tab-list-active {top: -12px;left: 9px;}

.trainers-list .tab-list-item.tab-list-active::before {
   content: "" !important;
    background: var(--cg-yellow);
}

.trainers-list .tab-list-item {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.trainers-list .tab-list-item::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: -1;
    top: -12px;
    left: -15px;
    transform: skewX(-30deg);
    background: rgba(0, 0, 0, 0.1);
    padding: 12px 14px !important;
}

.endButton-r {display: none;}
/*  .trainers-list .tab-list-active::before {
background: var(--cg-yellow);
} */

.trainers-list .tab-list-active {
  color: white;
  opacity: 1;
}

/* -------------------------- */

.tech-trainers-main {
  margin-left: 16%;
}

/* .tech-train-container {
position: relative;
} */

/*  .logo-column, */

.trainers-container {
  position: relative;
  height: 597px;
}

.trainers-container:focus {
  outline: none;
}

.tech-train-container img {
  width: 100%;
  border-radius: 170px;
  height: 100%;
  object-fit: cover;
}

.tech-main {
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tech-tain-fir {
  width: 137px;
  height: 137px;
  top: 81px;
  left: 0;
}

.tech-tain-sec {
  width: 100px;
  height: 100px;
  left: 280px;
}

.tech-tain-thi {
  top: 108px;
  width: 170px;
  height: 170px;
  left: 450px;
}

.tech-tain-for {
  width: 100px;
  height: 100px;
  top: 350px;
}

.tech-tain-fif {
  top: 217px;
  width: 138px;
  height: 138px;
  left: 230px;
}

.tech-tain-si {
  top: 381px;
  left: 540px;
  width: 100px;
  height: 100px;
}

.tech-tain-sev {
  top: 465px;
  left: 275px;
  width: 137px;
  height: 137px;
}

.tech-main span {
  z-index: 10;
  display: none;
  /* padding: 35px 30px; */
  padding: 20px;
  width: 220px;
  line-height: 16px;
  border-radius: 2px;
  box-shadow: 0px 14px 22px 4px #66666629;
  cursor: initial;
  transition: all 0.2s ease;
}

.tech-main:hover span {
  transition: all 0.2s ease;
  display: inline;
  position: absolute;
  border: 2px solid #fff;
  color: black;
  top: 70%;
  background: white;
  right: 52%;
  font-weight: bold;
}

.tech-main.tech-tain-fir:hover span {
  right: auto;
  left: 55%;
}

.tech-main.tech-tain-for:hover span {
  right: auto;
  left: 90%;
  top: auto;
  bottom: 30%;
}

.tech-main.tech-tain-si:hover span {
  top: auto;
  bottom: 40%;
  right: 75%;
}

.tech-main.tech-tain-sev:hover span {
  top: auto;
  bottom: 45%;
  right: auto;
  left: 70%;
}

.tech-main span h5 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 800;
  color: var(--cg-text);
}

.tech-main span p {
  font-size: 15px;
  line-height: 16px;
  color: var(--cg-text);
  margin-bottom: 0px;
}

.tech-main span:before {
  content: "";
  position: absolute;
  /*background-image: url("/images/train-cut.png");*/
  width: 18px;
  height: 18px;
  right: -2px;
  top: -18px;
}

.tech-main.tech-tain-fir span:before {
  right: auto;
  left: -2px;
  transform: rotate(90deg);
}

.tech-main.tech-tain-for span:before {
  right: auto;
  left: -18px;
  transform: rotate(0deg);
  top: auto;
  bottom: -2px;
}

.tech-main.tech-tain-sev span:before {
  top: auto;
  bottom: -2px;
  right: auto;
  left: -18px;
}

.tech-main.tech-tain-si span:before {
  top: auto;
  bottom: -2px;
  right: -18px;
  transform: rotate(90deg);
}

/* .tech-main span img {
  width: auto;
  border-radius: 0;
  margin-top: 15px;
} */

/* MAIN GALLERY PAGE */

.main-gallery .explore-list {
  width: 100%;
  max-width: 400px;
}

.main-gallery .explore-list p.explore-fir-list::before {
  background-color: white;
}

.main-gallery .explore-list p,
.main-gallery .explore-list a {
  color: black;
}

.main-gallery .image-container {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  justify-content: space-between;
}

.main-gallery .image-container .single-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.main-gallery .image-container .single-image {
  margin-bottom: 1%;
  width: 32.6%;
}

.main-gallery .image-container .single-image:nth-child(odd) {
  height: 547px;
}

.main-gallery .image-container .single-image:nth-child(2n + 2) {
  height: 400px;
}

/* VIDEO GALLERY PAGE */

.video-container .single-image {
  position: relative;
}

.video-container .single-image:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 68px;
  height: 68px;
  transform: translate(-50%, -50%);
  /*background-image: url("/images/play-icon.png");*/
}

.arrows {
  opacity: 0.5;
}

.arrows:hover {
  opacity: 1;
}

.section-3.trainers-section.overview-section {float: none !important;}
.cg-speakers.cg-speakers .column-12 {margin-bottom: 120px;}
.section-2.becomePhases .phaseDots p.yellow-bg::before,
.section-2.becomePhases .phase-single .single-phase-title h2 sub:before {background: #fbfbfb;}

/* IMAGE VIDEO PAGE */

.masonary-list-item {
  width: calc((100% / 3) - 10px);
  margin: 5px;
}

/* .masonary-list-item:nth-child(3n + 1) {
margin-right: 0px;
} */

.masonary-list-item:nth-child(even) img {
  height: 380px;
  object-fit: cover;
}

.masonary-ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.main-gallery .column-11 {
  margin: auto;
}

.main-gallery.section-5 {
  background-color: transparent;
}

.main-gallery.section-5 .yellow-bg::before {
  background: white;
}

.main-gallery.section-5 .brains-list .tab-list {
  width: 31%;
}

.main-gallery.section-5 .tab-list-item {
  color: var(--cg-text);
}

.flex-row.album-row {
  justify-content: space-between;
}

.flex-row.album-row .column-6 {
  flex-basis: 49%;
  margin-bottom: 25px;
}

.flex-row.album-row .column-6 .album-cover {
  width: 100%;
  /* background-attachment: fixed; */
  background-position: 0 0;
  background-repeat: no-repeat;
  height: 300px;
  position: relative;
  cursor: pointer;
}

.flex-row.album-row .column-6 .album-cover .album-title {
  color: white;
  font-weight: 700;
  position: absolute;
  bottom: 60px;
  left: 30px;
}

.flex-row.album-row .column-6 .album-cover p {
  position: absolute;
  bottom: 20px;
  left: 35px;
  font-size: 14px;
}

.flex-row.album-row .column-6 .album-cover p svg {
  padding-right: 10px;
  font-size: 18px;
  vertical-align: middle;
}

/* IN THE PRESS */

.all-press {
  padding: 150px 0 50px 0;
  justify-content: space-between;
}

.press-area {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 48px;
}

.press-post {
  height: 310px;
  width: 100%;
}

.press-post img {
  vertical-align: text-top;
  width: 100%;
}

.press-caption {
  padding: 35px 40px;
  border: 3px solid #fdfdfd;
  border-top: 0;
  transition: 0.3s all ease;
}

.press-area img {
  width: 100%;
  height: 100%;
}

.bars,
.close {
  display: none;
}

.press-caption h3 {
  font-size: 18px;
  font-weight: 900;
  margin: 25px 0;
  line-height: 30px;
}

.press-caption p {
  font-size: 16px;
  font-weight: 700;
  color: #4f4f4f;
  line-height: 30px;
  margin-bottom: 40px;
}

.press-caption a {
  font-size: 16px;
  font-weight: 700;
  color: #179e5a;
  position: relative;
}

.press-caption .openlink::after {
  content: " ";
  /*background-image: url("/images/arrow-right.png");*/
  width: 28px;
  height: 15px;
  position: absolute;
  top: 52%;
  transform: translateY(-50%);
  right: -35px;
}

.press-caption .openlink:hover::after {
  opacity: 0.7;
}

.press-area:hover .press-caption {
  box-shadow: 14px 13px 33px #f7f7f7;
}

.tag {
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.4);
}

.tag i {
  margin-right: 10px;
  color: #179e5a;
}

.section-3.top-five .flex-row {
  display: flex;
}

.section-3.top-five .flex-row .logo-container {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.section-3.top-five .flex-row .company-content h2 {
  padding-top: 40px;
}

.hire-counter {
  padding: 20px 0px;
  background: var(--cg-yellow);
  color: var(--cg-text) !important;
  /* width: 90%; */
}

.hire-counter .number-counter {
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  padding-top: 0px;
  align-items: center;
}

.hire-counter .number-counter .numN {
  padding: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  /* width: 80%; */
  text-align: center;
}

.hire-counter .number-counter .numN:last-child {
  border-bottom: none;
}

.hire-counter .number-counter .hc-content p {
  font-weight: 400;
  font-size: 16px;
}

.hire-counter .number-counter .hc-content .para-2 {
  font-weight: 900;
  font-size: 45px;
}

.hire-counter .number-counter .numN {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}

/* .hire-counter .number-counter .numN div:first-child {
width: 40%;
} */
.banner .page-title-wrapper .page-title-captions h3.albumname {
  font-size: 70px;
  letter-spacing: -4.5px;
}

.hire-counter .number-counter .numN div:last-child {
  margin-left: 30px;
}

.form-section .form-column {
  margin-left: 80px;
}

.form-section .form-column {
  color: var(--cg-text);
  font-size: 12px;
  font-weight: 700;
}

.form-section .form-column h3 {
  font-weight: 900;
  font-size: 24px;
  padding-bottom: 20px;
}

.form-section .form-column input:not([type="submit"]) {
  border: none;
  /* background-color: transparent; */
  border-bottom: 1px solid var(--cg-text);
  display: block;
  width: 100%;
  margin-top: 40px;
  outline: none;
  font-size: 16px;
  color: var(--cg-text);
}

.form-section .form-column div.form-row-1 {
  display: flex;
}

.form-section .form-column div.form-row-1 div {
  width: 50%;
}

.form-section .form-column div.form-row-1 div:first-child {
  margin-right: 30px;
}
.form-section .form-column form > div,.section-5 .form-column form > div {
    margin-bottom: 40px;
}
.form-section .form-column .form-message input {
  margin-top: 80px;
}

.form-section .form-column .form-submit input {
  background: transparent;
  font-size: 14px;
  font-weight: 800; 
  border: 2px solid var(--cg-text);
  display: block;
  padding: 12px 35px;
  outline: none;
  color: var(--cg-text);
  text-align: center;
  float: right;
  cursor: pointer;
}

.form-section .form-column .form-submit input:hover {
  background: var(--cg-green);
  border: 2px solid var(--cg-green);
  color: white;
}

/* SINGLE EVENT GRADUATION */

.news-banner {
  /* background: url(/images/header_img.jpg) 0 0 no-repeat; */
  padding: 100px 0px;
}

.news-banner-main h1 {
  font-size: 55px;
  text-align: left;
  margin: auto;
  font-weight: 700;
  position: relative;
  width: 100%;
}

.news-banner-main h1::after {
  content: "";
  position: absolute;
  bottom: -94px;
  left: 0px;
  right: 0px;
  border-bottom: 1px solid #f7f7f7;
}

.news-banner-main p {
  font-size: 16px;
  margin: auto;
  text-align: left;
  font-weight: 600;
  margin-top: 70px;
  line-height: 1.5em;
}

.main-speakers .graduation-inner-heading h2 {
  padding: 40px 0px 10px;
  font-size: 80px;
}

.main-speakers .graduation-talk p {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 500;
  margin-top: 40px;
}

.main-speakers .news-portion .column-5 {
  margin-left: 40px;
}

.applyDetails p,
.applyDetails ul li,
.applyDetails a {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.7em;
  color: var(--cg-text);
}

.applyDetails ul li {
  line-height: 3em;
}

.applyDetails ul li strong {
  font-weight: 800;
}

.applyDetails ul {
  list-style-type: disc;
}

.applyDetails .column-6 ul li {
  margin-left: 60px;
}

.applyDetails .column-12 ul li {
  margin-left: 20px;
}

.applyDetails p {
  padding: 10px 0px;
}

.applyDetails a {
  color: var(--cg-green);
  text-decoration: underline;
}

/* yasirabbas */

.press-caption a:hover {
  color: #179e5a8f;
}

.single-phase-list {
  min-height: 424px;
}

/* .single-phase-content .btn {
  margin-top: 0;
} */

.news-banner {
  background-size: cover;
  position: relative;
  min-height: 430px;
}

.news-banner-main .container {
  background-color: #fff;
  margin-top: -178px;
  position: relative;
}

.news-banner-main {
  padding: 0;
}

.news-banner-main .flex-row {
  padding: 50px 80px;
}

.news-portion .single-brain-img.diamond {
  box-shadow: inherit;
}

.main-speakers .graduation-inner-heading h2,
.main-speakers .graduation-inner-heading h2 span {
  float: left;
  width: 100%;
}

.main-speakers .graduation-inner-heading h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--cg-text);
  position: relative;
  margin-top: 28px;
  float: left;
  width: 100%;
}

.main-speakers .graduation-inner-heading h4::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 0px;
  border-bottom: 1px solid #f7f7f7;
  right: 0;
}

.main-speakers .graduation-inner-description h4 {
  margin-top: 70px;
  float: left;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.main-speakers .graduation-inner-description p {
  font-size: 16px;
  margin-top: 30px;
  max-width: 90%;
  font-weight: 600;
  line-height: 1.8;
  float: left;
}

.main-speakers .flex-row {
  align-items: center;
  margin-top: 70px;
}

.main-speakers .diamond {
  background-color: transparent;
}

.main-speakers .single-brain-img.diamond {
  width: 460px;
  height: 460px;
}

.main-speakers .single-brain-img.diamond.right-wrp-img {
  float: right;
}

.main-speakers .single-brain-img.diamond::before {
  z-index: 999;
}

.main-speakers .single-brain-img.diamond.right-wrp-img::before {
  left: -94px;
  bottom: 188px;
  top: -138px;
}

.main-speakers .single-brain-img.diamond.right-wrp-img::after {
  left: 215px;
  bottom: -92px;
}

.main-speakers .single-brain-img img {
  position: relative;
  z-index: 9;
}

.cg-speakers .alumni {
  font-size: 24px;
  text-align: center;
  line-height: 1.7;
  font-weight: 600;
}

.cg-speakers .graduation-inner-heading h2,
.cg-speakers .graduation-inner-heading h2 span {
  float: left;
  width: 100%;
}

.cg-speakers .graduation-inner-heading h2 {
  padding: 40px 0px 10px;
  font-size: 80px;
}

.cg-speakers .graduation-inner-heading h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--cg-text);
  position: relative;
  margin-top: 28px;
  float: left;
  width: 100%;
}

.cg-speakers .graduation-inner-description p {
  font-size: 16px;
  margin-top: 30px;
  max-width: 90%;
  font-weight: 600;
  line-height: 1.8;
  float: left;
  margin-top: 70px;
}

.cg-speakers .graduation-inner-heading h4::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 0px;
  border-bottom: 1px solid #f7f7f7;
  right: 0;
  max-width: 78%;
}

.cg-speakers img {
  width: 194px;
  height: 194px;
  border-radius: 100%;
  object-fit: cover;
  z-index: 99;
  position: relative;
}

.graduation-inner-img {
  position: absolute;
  right: 0;
  top: -16px;
}

.cg-speakers {
  padding-bottom: 0;
  position: relative;
}

.cg-speakers .column-6 {
  position: relative;
  flex-basis: 46%;
  margin: 0 25px;
}

.cg-speakers .column-6:nth-child(odd) {
  margin-top: 150px;
}

.cg-speakers .column-12 {
  margin-bottom: 120px;
}

.cg-speakers::before {
  /* content: url(/images/aboutWave.png); */
  height: auto;
  position: absolute;
  top: 38%;
  right: 0;
}

.cg-speakers .column-6:nth-child(odd) .graduation-inner-img::after {
  display: none;
}

.cg-speakers .graduation-inner-img::after {
  /* content: url(/images/dots_pattern.png); */
  height: auto;
  position: absolute;
  left: -87px;
  bottom: 116px;
}

.main-speakers .single-brain-img.diamond::after {
  /* content: url(/images/dots_pattern.png); */
  width: auto;
}

.cg-projects .container {
  background-color: #f7f7f7;
  padding: 27px 0px;
}

.cg-projects p {
  text-align: center;
  font-size: 16px;
  max-width: 80%;
  font-weight: 600;
  line-height: 1.8;
  margin: auto;
}

.cg-projects h2 {
  padding-top: 0;
  font-size: 55px;
  text-align: center;
  padding-bottom: 30px;
}

.dates span {
  font-size: 15px;
  font-weight: 700;
  margin-right: 50px;
  display: flex;
  align-items: center;
}

.dates {
  margin-top: 40px;
  display: flex;
}

.dates img {
  margin-right: 15px;
}

.wrp-speakers .graduation-inner-description p {
  margin-top: 70px;
}

.wrp-speakers .graduation-inner-heading h4::after {
  max-width: 80%;
}

.event-highlights ul li a {
  font-weight: 700;
  font-size: 16px;
  color: var(--cg-text);
}

.event-highlights ul li {
  font-weight: 700;
  font-size: 16px;
  color: var(--cg-text);
  line-height: 2em;
  margin: 30px 0px;
  list-style-type: disc;
  max-width: 90%;
}

.event-highlights a.btn {
  font-size: 14px;
  font-weight: 800;
  border: 2px solid var(--bg-gray);
  padding: 15px 35px;
  /* margin-top: 30px; */
  display: inline-block;
  border-color: var(--cg-text);
}

/* FORM  */

.form-section select {
  background-color: transparent;
  border: 1px solid var(--cg-text);
  display: inline-block;
  width: 100%;
  margin: 30px 0px 0px;
  outline: none;
  font-size: 16px;
  /* padding: 10px 0px; */
  height: 45px;
  color: var(--cg-text);
}

.form-section h5 {
  font-size: 18px;
  font-weight: 500;
  padding: 20px 0px 60px;
  line-height: 1.4em;
}

.form-section .mentor-head {
  padding-bottom: 0px !important;
}

.form-section input[type="file"] {
  border-bottom: none !important;
}

/* .privacy-policy h1 {
  font-size: 30px;
  font-weight: 600;
} */

.privacy-policy h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 2em;
}

.privacy-policy p,
.privacy-policy li,
.privacy-policy a {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy ul {
  list-style: disc;
}

.privacy-policy li {
  margin-left: 30px;
}

.brains-desc p {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 1.3em;
}

.brains-desc {
  margin: 40px 0px 0px;
  max-width: 75%;
}

.calendarIcon {
  color: var(--cg-green);
  margin-right: 10px;
  font-size: 20px;
}

/* Event Single */
.event-highlights .column-6 .tech-trainers-main {
  position: relative;
  margin-left: 2%;
}

.bg-color-grey {
  background: #f7f7f7;
}
ul.graduation-list {
  /* list-style-image: url("/images/list-dot-image.png"); */
  margin-left: 20px;
  font-size: 15px;
  max-width: 95%;
  margin: auto;
}
ul.graduation-list li {
  line-height: 1.6em;
  padding-bottom: 20px;
  padding-left: 10px;
  font-weight: 600;
}
ul.graduation-list .nested-graduationlist {
  padding-left: 50px;
  /* list-style-image: url("/images/nested-dot-style.png"); */
  margin-left: 0;
}
ul.graduation-list .nested-graduationlist li:first-child {
  padding-top: 20px;
}

.main-speakers.wrp-speakers {
  padding-top: 0px;
}

.applyDetails .flex-row {
  padding-top: 0px;
}

.section-3.overview-section .flex-row .company-content {
  width: 55%;
}
.image-cg-flex {
  display: flex;
  justify-content: space-around;
  padding: 30px 20px;
}
.cg-projects.first-grad-cg p {
  max-width: 90%;
}
.main-speakers .graduation-inner-description p strong {
  font-weight: 700;
}
.cg-projects p a {
  font-size: 16px;
  text-decoration: underline;
}

section.pasha-image-ban {
  text-align: center;
  padding: 0;
}
.cg-projects p i {
  font-style: italic;
  color: #547dbf;
}
.cg-speakers .graduation-inner-description h4 {
  margin-top: 70px;
  float: left;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.cg-speakers .graduation-inner-description p.graduation-inner-para-top {
  margin-top: 30px;
}
.event-highlights li a {
  text-decoration: underline;
}
.trainers-container .tech-main .trainersLink {
  background: #0072b1;
  padding: 8px;
  border-radius: 25%;
  float: right;
  /* padding-top: 0px; */
}
/* .trainers-container .tech-main .trainersLink:hover {
  border: 2px solid var(--cg-green);
  color: var(--cg-green);
  background: white;
} */

.trainers-container .tech-main img {
  border-radius: 50%;
}

.trainers-container .tech-main .trainersLink img {
  border-radius: 0px;
}

.cg-speakers.cg-speakers .column-12 {
  margin-bottom: 20px;
}
.google-io-sec.cg-speakers img {
  border-radius: 0;
  z-index: auto;
  object-fit: unset;
  box-shadow: 0px 0px 38px #bbc3bb78;
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}
.google-io-sec.cg-speakers .column-4 > div {
  padding: 15px;
  margin-top: 40px;
}
.google-io-sec.cg-speakers .column-4 h4 {
  color: #265a9c;
  font-weight: 400;
  font-size: 32px;
  margin: 30px 0px 20px 0px;
  text-align: center;
}
.google-io-sec.cg-speakers .column-4 p {
  font-size: 16px;
  text-align: center;
  line-height: 1.8;
  padding: 0px 10px;
  font-weight: 600;
}
.pasha-prowomen.news-banner {
  /*background-image: url("/images/pasha-header.jpg");*/
}
.google-io-banner.news-banner {
  /*background-image: url("/images/google-io-header.jpg");*/
}
.first-graduation-banner.news-banner {
  /*background-image: url("/images/first-graduation-header.jpg");*/
}
.pasha-highlights.event-highlights ul li {
  list-style-type: none;
}
.pasha-highlights.event-highlights a {
  color: var(--cg-text);
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.section-3.trainers-section.overview-section .flex-row {
  display: flex !important;
}
.section-3.trainers-section.overview-section .flex-row .logo-column {
  margin: 0px;
  width: inherit;
}
.section-3.trainers-section.overview-section
  .flex-row
  .logo-column
  .trainers-container {
  margin-top: 200px;
}

.section-3.trainers-section.overview-section .flex-row .company-content {
  width: 90%;
}
.image-cg-flex {
  justify-content: center;
  padding: 20px 20px;
  flex-wrap: wrap;
}
.image-cg-flex img {
  padding: 10px;
}

.react-photo-gallery--gallery div {
  justify-content: center;
}

.flex-row.video-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.flex-row.video-row .videos-a {
  flex-basis: 31%;
  margin: 10px;
}

/* .flex-row.video-row .videos-a div {
  width: 70%;
} */

.flex-row.news-portion .diamond.diamond-small {
  /* background: url("/images/neelam.png") 0 0 no-repeat;
  background-position: 70% 15%; */
  width: 200px;
  height: 200px;
  box-shadow: none;
  top: -180px;
  left: 49%;
  position: absolute;
}

.flex-row.news-portion .double-images,
.flex-row.news-portion .tripple-images {
  position: relative;
}

.flex-row.news-portion .diamond.diamond-small:last-child {
  left: 24.5%;
  top: 30px;
}

.flex-row.news-portion .tripple-images .diamond.diamond-small:nth-child(2) {
  left: 100px;
  top: -160px;
}

.flex-row.news-portion .tripple-images .diamond.diamond-small:last-child {
  top: 100px;
  left: 180px;
}

.flex-row.news-portion .tripple-images .diamond.diamond-small:first-child {
  left: 300px;
  top: -90px;
  transform: none;
  -webkit-transform: none;
}

.flex-row.news-portion .diamond.diamond-small.neelam {
  /* background: url("/images/neelam.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.muslim {
  /* background: url("/images/muslim.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.asra {
  /* background: url("/images/asra.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.anam {
  /* background: url("/images/anam.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.mashood {
  /* background: url("/images/mash2.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.ayaz {
  /* background: url("/images/ayaz2.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.trip1 {
  /* background: url("/images/trip1.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.trip2 {
  /* background: url("/images/trip2.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}

.flex-row.news-portion .diamond.diamond-small.trip3 {
  /* background: url("/images/trip3.png") 0 0 no-repeat; */
  background-position: 70% 15%;
}
.main-gallery.section-5 .tab-list-item.tab-list-active {color: white;}

.readmore-aboutus, .section-3 .company-content.trainer-content .short-text {
  font-size: 16px;
  line-height: 2em;
  margin: 30px 0px;
  color: var(--cg-text);
  padding: 50px 0px;
  font-weight: 700;
  white-space: pre-line;
}
.readMoreText {
  color: var(--cg-orange) !important;
    padding-left: 20px;
}
















/* ================= MEDIA QUERY ======================== */
@media only screen and (max-width: 1500px) {
.applyDetails p, .applyDetails ul li, .applyDetails a {
    font-size: 14px;
}
.banner .page-title-wrapper .page-title-captions h3 {font-size: 70px;}
.banner .page-title-wrapper ul li,
.banner .page-title-wrapper ul li a {font-size: 11px;}

.section-3 p.yellow-bg {
  margin: 30px 0px;
}
.hire-counter .number-counter .numN {
      padding: 45px;
}
.form-section .form-column h3 {
  padding-bottom: 80px;
}
  .single-brain-img.diamond{width: 390px;
    height: 390px;}
    .section-3.trainers-section.overview-section .flex-row .logo-column {flex-basis: 50%;}
}
@media only screen and (max-width: 1280px) {
  header .nav-container nav .nav-item, header .nav-container nav div:nth-child(4) {
    width: 20.88571%;
    padding: 14px 0px;
  }
  .svg-inline--fa.fa-fw {
    width: 0.75em;
  }
  .banner .page-title-wrapper .page-title-captions h3 {
    font-size: 65px;
  }
  .single-pro-showcase p {
    font-size: 14px;
  }
  .phase-tesimonials .column-4,
  .phase-tesimonials .column-8 {
    flex-basis: 100%;
  }
  .phase-tesimonials .container:before {
    left: auto;
    right: 2%;
    top: -60px;
  }
  .phase-tesimonials svg {
    top: auto;
    bottom: 0;
  }
  .phase-tesimonials .single-test-phase .column-8 {
    margin-top: 40px;
  }
  .phase-tesimonials .single-test-phase .btn {
    display: none;
  }
  .section-5.aboutBrains .column-7,
  .section-5.aboutBrains .column-5 {
    flex-basis: 100%;
  }
  .brain-img-column {
    text-align: -webkit-center;
    padding-top: 90px;
  }
  .main-gallery.section-5 .brains-list .tab-list {
    width: 34%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1080px) {
  .banner .page-title-wrapper .page-title-captions h3 {
    font-size: 65px;
  }
  .single-pro-showcase .column-6 {
    flex-basis: 100%;
  }
  .project-showcase {
    padding-bottom: 80px;
  }
  .project-showcase .arrows {
    top: auto !important;
    bottom: -50px;
  }
  .phase-tesimonials .single-test-phase p,
  .phase-tesimonials .single-test-phase p a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .tab-content {float: none;}
  .trainers-list.overview-list,
  .section-3.trainers-section.overview-section .flex-row .logo-column {flex-basis: 100%;}
  .section-3.trainers-section.overview-section .flex-row .logo-column .trainers-container {margin-top: 0;}
  .banner .page-title-wrapper .page-title-captions h3.albumname {font-size: 50px !important;}
  h1.albumIntro {font-size: 30px !important;letter-spacing: -2px !important;}
  .flex-row.news-portion .tripple-images {height: 500px;}
  .flex-row.news-portion .tripple-images .diamond.diamond-small:first-child {top: 22%;left: 44%;}
  .flex-row.news-portion .tripple-images .diamond.diamond-small:nth-child(2) {top: 0;left: 15%;}
  .flex-row.news-portion .tripple-images .diamond.diamond-small:last-child {top: 300px;left: 26.5%;}
  .main-speakers .flex-row.news-portion .column-6{width: 90%;}
}

@media only screen and (max-width: 980px) {
  .single-brain-img.diamond {
    width: 370px;
    height: 370px;
  }
}

@media only screen and (max-width: 768px) {
  .banner .page-title-wrapper .page-title-captions h3 {
    font-size: 50px;
    letter-spacing: -4px;
  }
  .banner {
    padding: 15px 20px;
  }
  .banner .page-title-wrapper ul li {
    font-size: 10px;
  }
  .banner .page-title-wrapper ul,
  .banner .page-title-wrapper .page-title-captions {
    width: auto;
  }
  .phase-tesimonials .single-test-phase p,
  .phase-tesimonials .single-test-phase p a {
    font-size: 18px;
    line-height: 32px;
  }
  .single-brain-img.diamond {
    width: 320px;
    height: 320px;
  }
  .section-5.aboutBrains {
    overflow: hidden;
  }
  .section-5.aboutBrains .tab-list {
    width: 100%;
  }
  .section-3.trainers-section {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .section-3 .logo-column .logo-container .logo-item {    text-align: center;}
  .brains-desc {max-width: 100%;}
  .brains-desc p {font-size: 14px !important;}
  .section-3 .logo-column .logo-container .logo-item {text-align: center;}
  .section-3.trainers-section.overview-section {overflow: hidden;}
}

@media only screen and (max-width: 640px) {
  .flex-row.video-row .videos-a {flex-basis: 100%;}
  .section-5 .brains-contact a {
    word-break: initial;
  }
  .form-column h5 {
    font-size: 14px;
  }
  .brains-desc p {font-size: 12px !important;}
  .section-3.trainers-section .trainers-list.overview-list .tab-list {
    flex-direction: column;
      position: relative;
  }
  .section-3.trainers-section .trainers-list.overview-list .tab-list li:nth-child(1):before {
    content: "-";
      padding-right: 20px;
      padding-left: 20px;
  }
  .overview-list li.tab-list-item {
      margin: 10px 0px;
  }
  .section-3 .overview-list h2 {
    padding-top: 34px;
  }
  .section-3.trainers-section .overview-list .trainer-content p {
    padding-top: 0;
  }
  .banner .page-title-wrapper .page-title-captions h3.albumname {font-size: 40px !important;}
  h1.albumIntro {font-size: 26px !important;letter-spacing: -2px !important;}
  .main-gallery.section-5 h3{font-size: 22px !important;}
  .section-1 .flex-row {padding-bottom: 318px;}
  /* .section-3 .company-list .tab-list-item {width: 80%;} */
  .section-3 .friends-of-cg h2 {
    padding-bottom: 40px;
  }
  body .project-showcase {
    padding-top: 80px;
  }
  body .project-showcase .phaseDots {
    top: -30px;
  }
  .section-3.trainers-section.overview-section .tech-tain-thi {
    right: -4% !important;
  }
  .section-3.trainers-section.overview-section .tech-main{overflow: hidden;}
  .section-3.trainers-section.overview-section .tech-main img{border-radius: 0;width: 100%;height: 100%;object-fit: cover;}
  .applyDetails p, .applyDetails ul li, .applyDetails a {
    font-size: 12px;
  }
  .becomePhases.section-2 {padding-bottom: 15px;}
  .single-brain-img.diamond {background-size: contain !important;}
  body .phase-tesimonials .short-text {font-size: 18px;line-height: 28px;}
  .main-speakers .news-portion .column-5 {margin-left: 0 !important;}
  .section-3.trainers-section .trainer-content p {padding:0;}

  .banner .page-title-wrapper,
  .banner .page-title-wrapper ul,
  .banner .page-title-wrapper .page-title-captions {
    display: block;
  }
  .banner .page-title-wrapper .page-title-captions h3 {
    font-size: 40px;
    line-height: unset;
    margin-bottom: 20px;
  }
  .phase-tesimonials .single-test-phase p,
  .phase-tesimonials .single-test-phase p a {
    font-size: 15px;
    line-height: 36px;
  }
  .phase-tesimonials .single-test-phase img {
    margin-bottom: 20px;
    max-width: 60px;
  }
  .single-brain-img.diamond {
    width: 280px;
    height: 280px;
    background-size: contain;
  }
  .section-5.aboutBrains .container {
    overflow: hidden;
  }
  .phase-tesimonials .single-test-phase p,
  .phase-tesimonials .single-test-phase p a {
    font-size: 12px;
    line-height: 22px;
  }
  .single-pro-showcase p {
    max-width: 100%;
  }
  .section-3.trainers-section .trainers-list .tab-list {
    top: auto;
    left: 0;
  }
  .section-5.aboutBrains .tab-list {
    margin-top: 50px;
  }
  .section-5 .brains-list .tab-list-item {
    font-size: 10px;
    /* margin: 0 7px; */
  }
}

@media only screen and (max-width: 360px) {
  .form-column h5 {
    font-size: 12px;
  }
  body .phase-tesimonials .short-text {font-size: 16px;line-height: 24px;}
  .single-pro-showcase p {font-size: 12px;line-height: 22px;}
}

@media only screen and (max-width: 320px) {
  .section-5 .brains-contact a {
    word-break: break-all;
  }
}

@media only screen and (max-width: 480px) {
  .single-brain-img.diamond {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 420px) {
  .section-3.trainers-section .trainers-list .yellow-bg,
  .section-3.trainers-section .trainers-list .tab-list-item,
  .project-showcase .phaseDots p.yellow-bg,
  .project-showcase .phaseDots button {
    font-size: 10px;
  }
  .section-5 .brains-list .tab-list-item::before,
  .trainers-list .tab-list-item::before {width: 90%;}
}


/* .main-gallery.section-5 .tab-list .tab-list-item:first-child {
color: white;
position: relative;
background: var(--cg-yellow);
padding: 12px 20px;
padding-right: 40px;
width: auto;
position: absolute;
top: -12px;
left: 0px;
}

.main-gallery.section-5 .tab-list .tab-list-item:first-child::before {
content: "";
width: 22px;
height: 40px;
background: white;
position: absolute;
top: 0px;
right: -11px;
transform: skew(-30deg);
} */

@media only screen and (max-width: 980px) {
  .masonary-list-item {
    width: calc(100% / 2.3);
  }
}



@media only screen and (max-width: 801px) {
  .phaseDots p:not(.yellow-bg) {
    display: none;
  }
}

/* Media Query */
/* i need to check this again urooj */
@media only screen and (max-width: 1380px) {
  .tech-tain-thi {
    left: 67.5%;
  }
  .tech-tain-si {
    left: 81%;
  }
  .tech-tain-fif {
    left: 34.5%;
  }
  .tech-tain-sev {
    left: 41.5%;
  }
  .tech-tain-sec {
    left: 42%;
  }
}
@media only screen and (max-width: 1024px) {
  .cg-speakers.google-io-sec .column-4 {
    flex-basis: 49.933%;
  }
  .event-highlights .column-6 .tech-trainers-main {
    height: 550px;
  }
  .applyDetails .column-6 {
    flex-basis: 100%;
  }
  .applyDetails .column-6 iframe {
    width: 100% !important;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .event-highlights {
    overflow: hidden;
  }
  .section-2 .phase-single .single-phase-title h3 {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 640px) {
  .cg-speakers.google-io-sec .column-4 {
    flex-basis: 100%;
  }
  .tech-tain-sev {
    width: 127px;
    height: 127px;
  }
  .tech-tain-si {
    width: 80px;
    height: 80px;
  }
  .tech-tain-fif {
    height: 128px;
    width: 128px;
  }
  .tech-tain-for {
    width: 80px;
    height: 80px;
  }
  .tech-tain-thi {
    width: 150px;
    height: 150px;
  }
  .tech-tain-sec {
    width: 80px;
    height: 80px;
  }
  .tech-tain-fir {
    width: 127px;
    height: 127px;
  }
}
@media only screen and (max-width: 480px) {
  .tech-tain-sev {
    width: 117px;
    height: 117px;
  }
  .tech-tain-si {
    width: 70px;
    height: 70px;
  }
  .tech-tain-fif {
    height: 118px;
    width: 118px;
  }
  .tech-tain-for {
    width: 70px;
    height: 70px;
  }
  .tech-tain-thi {
    width: 140px;
    height: 140px;
  }

  .tech-tain-sec {
    width: 70px;
    height: 70px;
  }
  .tech-tain-fir {
    width: 117px;
    height: 117px;
  }
  .tech-tain-thi {
    right: -7%;
    left: auto;
  }
  .tech-tain-fif {
    left: 25.5%;
    top: 257px;
  }
  .tech-tain-for {
    top: 390px;
  }
  .tech-tain-sev {
    left: 31.5%;
  }
}


@media only screen and (max-width: 1024px) {
  .flex-row.news-portion .diamond.diamond-small {
    top: 0;
  }
  .flex-row.news-portion .double-images,
  .flex-row.news-portion .tripple-images {
    height: 400px;
  }
  .flex-row.news-portion .diamond.diamond-small:last-child {
    top: 210px;
  }
}

.becomePhases.section-2 .single-phase-content a.btn {
  display: none;
}

.becomePhases.section-2,
.becomePhases.section-2 .flex-row.phase-row {
  padding-bottom: 0px;
}

@media only screen and (max-width: 1350px) {
  .project-showcase .phaseDots {
    display: none;
  }
}

/* .section-3.trainers-section .trainer-content p {
  padding: 50px 0px;
} */


small.warning-text {color: red;margin: 10px 0px;
    display: block;
  }
.alert-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 50px;
}
.alert-section .image {margin-bottom: 40px;text-align: center;}
.alert-section .image img {max-width: 95%}
.text-align {text-align: center;width: 100%;}
.text-align h1 {
  font-size: 120px;
  font-weight: 800;
  line-height: 80px;letter-spacing: -9px;
}
.text-align h1 span {
    color: var(--cg-green);
}
.text-align h4 {
  font-size: 25px;
  font-weight: 800;
  line-height: 20px;
  padding: 2.5% 2%;
  letter-spacing: -1px;
}
.text-align a {
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    color: #179e5a;
    text-decoration: underline;
    margin-top: 30px;
    display: inline-block;
}
.page-not-found .text-align h4 {max-width: 680px;
    margin: auto;    line-height: 40px;
    padding: 0%;}
.page-not-found .text-align h4 {font-size: 25px;}
.page-not-found .alert-section {    padding-top: 150px;
    padding-bottom: 150px;}
.page-not-found .text-align h1 {
    font-size: 325px;
    font-weight: 800;
    line-height: 260px;position: relative;display: inline-block;margin-bottom: 20px;}
.page-not-found h1:before {
    content: "";
    position: absolute;
    /*background-image: url('/images/errorpage-wave.png');*/
    width: 205px;
    height: 60px;
    background-repeat: no-repeat;
    left: -10%;
    top: 13%;
}
.page-not-found h1:after {
    content: "";
    position: absolute;
    /*background-image: url('/images/errorpage-wave.png');*/
    width: 178px;
    height: 60px;
    background-repeat: no-repeat;
    right: -16%;
    bottom: 6%;
    z-index: -1;
    background-position: right;
}
.page-not-found .text-align a {
    margin: 0;
    font-size: 25px;
}

@media only screen and (max-width: 1500px) {
  .alert-section .image img {
    max-width: 80%;
  }
  .text-align h1 {
    font-size: 100px;
  }
  .page-not-found .text-align h4,.page-not-found .text-align a,.text-align h4 {
    font-size: 22px;}
  .page-not-found h1:before {
    width: 165px;
        background-position: right;
    background-size: contain;
  }
  .page-not-found .text-align h1 {
    font-size: 275px;}
    .page-not-found h1:after {width: 155px;bottom: 12%;background-size: contain;}
}
@media only screen and (max-width: 1280px) {
  .alert-section {padding-top: 69px;}
  .alert-section .image img {
    max-width: 60%;
  }
  .alert-section .image {
    margin-bottom: 20px;
  }
  .text-align h1 {
    font-size: 85px;
  }
  .page-not-found .text-align h4,.text-align h4 {
    font-size: 20px;
    padding: 1.5% 2%;
  }
  .page-not-found .text-align a {
    font-size: 20px;}
  .text-align a {margin-top: 20px;}
  .text-align {padding:0px 3%;}
  .page-not-found .text-align h1 {
    font-size: 235px;line-height: 100px;
  }
  .page-not-found h1:before {
    width: 140px;    top: -32%;}
    .page-not-found h1:after {
    width: 130px;
    bottom: -35%;}
}
@media only screen and (max-width: 640px) {
  .text-align h1 {
    font-size: 65px;
    letter-spacing: -5px;
  }
  .page-not-found .text-align h4,.text-align h4,.page-not-found .text-align a {font-size: 16px;
    line-height: 22px;}
  .text-align a {font-size: 14px;}
  .alert-section {padding-top: 30px;padding-bottom: 20px;}
  .alert-section .image img {max-width: 40%;}
  .page-not-found .text-align h1 {
    font-size: 150px;
  }
  .page-not-found h1:before {
    width: 110px;
    top: -2%;left: -20%;
  }
  .page-not-found h1:after {
      width: 110px;
      bottom: -14%;
      right: -23%;
  }
  .page-not-found .alert-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 480px) {
  .alert-section .image img {max-width: 50%;}
  .text-align h1 {font-size: 50px;letter-spacing: -3px;}
.page-not-found h1:after {
    width: 75px;
    bottom: -4%;
    right: -24%;
}.page-not-found h1:before {
    width: 80px;
    top: 0%;
    left: -20%;
}
.page-not-found .text-align h1 {
    font-size: 100px;
}
}





 .section-5 .brains-list h2 {
    width: 70%;
    display: inline-block;
    margin-left: 50px;
}
.single-brain-img.diamond {
    background: rgba(0,0,0,.1);
    border-radius: 30px;
}
.section-3 .logo-item a {
    padding: 0;
    border: 0;
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
}

.section-3 .logo-item a:hover {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}
.section-3 .logo-item a:focus {
    outline: none;
}

.contact-detail-head .contact-image-container {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
}
.contact-detail-head .contact-image-container img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.contact-detail-head .single-contact-img.diamond {
  width: 135px;
  height: 135px;
  border-radius: 20px;
  box-shadow: -69px 53px 46px 0px rgba(0, 0, 0, 0.4);
  position: relative; 
  display: inline-block;
}

.contact-detail-head .contact-img-column {
  padding: 140px 0px;
}

.contact-detail-head .single-contact-img.diamond::before {
  /* content: url(/images/aboutWave.png); */
  width: 100px;
  height: auto;
  position: absolute;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  z-index: -1;
  left: -70px;
  top: -40px;
} 


#contact .column-5 {
  flex-basis: 45% ;
}
#contact.section-5 .form-column {
  margin-left: 50px ; 
}


.tooltip{
  position: relative;
}
.tooltip span {
  z-index: 10;
  display: none; 
  padding: 20px;
  width: 220px;
  line-height: 16px;
  border-radius: 2px;
  box-shadow: 0px 14px 22px 4px #66666629;
  cursor: initial;
  transition: all 0.2s ease;
}

.tooltip span:before {
    content: "";
    position: absolute;
    /*background-image: url(/images/train-cut.png);*/
    width: 18px;
    height: 18px;
    right: -2px;
    top: -18px; 
}
.tooltip:hover span {
  display: inline;
  position: absolute;
  border: 2px solid #fff;
  color: black;
  top: 45px;
  background: white;
  right: 52%;
  font-weight: bold;
}
.trainer-content .btn.tooltip span:before,.tooltip.fix-issue span:before,  a.endButton-d.tooltip span:before {
  content: "";
  position: absolute;
  /*background-image: url(/images/train-cut.png);*/
  width: 18px;
  height: 18px;
  top: -18px;
  left: -2px;
  transform: rotate(90deg);
}
.trainer-content .btn.tooltip:hover span, .tooltip.fix-issue:hover span, a.endButton-d.tooltip:hover span {
    right: 0;
    left: 40%;
}

